.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 1000px;
  width: 100%;
  background-color: blue;
}

.documentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
